import React, { useRef, useEffect } from "react";
import { CTX } from "../components/Store";
import * as d3 from 'd3';
import { clrs } from './util';
//import Icon from "./Icon";

const margin = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
};

export default function Timeline() {

  const tlncont = useRef(null);
  const evtcont = useRef(null);
  const { st, sendReq } = React.useContext(CTX);
  const tx = -72;
  const ty = 20;

  useEffect(() => {
    if (st.timeline.length > 0) {
     const tln = d3.select(tlncont.current);
     const evt = d3.select(evtcont.current);
     const evDates = [];

     tln.selectAll('*')
       .remove();
     evt.selectAll('*')
       .remove();

     // aspects glyphs
     for (let i = 0; i < st.timeline.length; i++){
       const pap =
        st.timeline[i].interp.description.split(":");

       evt.append("use")
          .attr("xlink:href","/static/media/icons." + st.icons_id + ".svg#" +  pap[0])
          .attr("transform", `translate(${tx}, ${ty + (i * 40)}) scale(.30)`);
       if ( pap[1] === "sgn")  {
	   if (pap[2] === "leo") {
              evt.append("use")
                .attr("xlink:href","/static/media/icons." + st.icons_id + ".svg#lex")
                .attr("transform",`translate(${tx + 23}, ${ty + 5 +(i * 40)}) scale(.2) `);
           } else {
              evt.append("use")
                .attr("xlink:href","/static/media/icons." + st.icons_id + ".svg#" +  pap[2])
                .attr("transform",`translate(${tx + 23}, ${ty + 5 +(i * 40)}) scale(.2) `);
	   }
       } else {
          evt.append("use")
             .attr("xlink:href","/static/media/icons." + st.icons_id + ".svg#" +  pap[1])
             .attr("transform",`translate(${tx + 23}, ${ty + 5 +(i * 40)}) scale(.8)`);
          evt.append("use")
             .attr("xlink:href","/static/media/icons." + st.icons_id + ".svg#" +  pap[2])
             .attr("transform", `translate(${tx + 36}, ${ty + (i * 40)}) scale(.30)`);
       }
     }

     const width = 540 - margin.right - margin.left;
     const g = tln.selectAll('.parent')
       .data(st.timeline);

     // convert jul dates to js Date objs

     for (let l = 0; l < st.timeline.length; l++) {
       for (let j = 0; j < st.timeline[l].eldates.length; j++) {
         evDates.push(new Date(new Date((st.timeline[l].eldates[j].sdt - 2440587.5) * 86400000.0)));
         evDates.push(new Date(new Date((st.timeline[l].eldates[j].edt - 2440587.5) * 86400000.0)));
       }
     }

     if ( evDates.length > 0) {
       const tlmin = new Date(Math.min.apply(null, evDates));
       const tlmax = new Date(Math.max.apply(null, evDates));
       tlmin.setMonth(tlmin.getMonth());

     // chart plot area
     tln.append('g')
       .attr('class', 'bars')
       .attr('transform', `translate(${margin.left + 10}, ${margin.top})`);

     const xDomain = [tlmin, tlmax];
     const xScale = d3.scaleTime()
       .domain(xDomain)
       .range([margin.left + 10, width]);

     tln.append('g')
      .attr('class', 'axis axis-x')
      .attr('transform',
         'translate(' + 0 + ',' + 0 + ')')
      .style('fill', 'red')
      .call(
        d3.axisBottom(xScale)
         .ticks(d3.timeMonth.every(1))
         .tickFormat(d3.timeFormat('%m/%y'))
      );

     // tooltip groups & events

     const gEnter = g
      .enter()
      .append('g')
      .attr('class', 'parent')
      .attr('transform', (d, i) => 'translate(0,' + (((i + 1) * 40) -20) + ')')
      .style('fill', 'none')
      .on('mouseover', (d, i) => { })
      .on('mouseout', (d, i) => { })
      .on('touchend', (d, i) => { });

    // lines groups & events
    let clr = '';
    const line1 = gEnter
     .selectAll('.child')
     .data(d => {
       const sdt = [];
       for (let l = 0; l < d.eldates.length; l++) {
         sdt.push(new Date((d.eldates[l].sdt - 2440587.5) * 86400000.0));
       }
       return sdt;
     })
     .enter()
     .append('line')
     .style(
       'stroke',
       (d, i) => {
         // same color for multiple events
         if (i === 0) {
           clr = clrs[Math.floor(Math.random() * clrs.length)];
         }
         return clr;
       }
     )
     .style('stroke-width', '7px')
     .attr('class', 'child')
     .attr('x1', d => xScale(d))
     .attr('y1', 15);

     gEnter
     .selectAll('.child')
     .data(d => {
       const edt = [];
       for (let l = 0; l < d.eldates.length; l++) {
        edt.push(new Date((d.eldates[l].edt - 2440587.5) * 86400000.0));
       }
       return edt;
     })
     .merge(line1)
     .attr('x2', d => xScale(d))
     .attr('y2', 15);

   gEnter
     .selectAll('.xdt')
     .data(d => {
       const xdt = [];
       for (let l = 0; l < d.xdates.length; l++) {
         xdt.push(new Date((d.xdates[l].jd - 2440587.5) * 86400000.0));
       }
       return xdt;
     })
     .enter()
     .append('circle')
     .style('stroke', 'black')
     .style('stroke-width', '5px')
     .attr('class', 'xdt')
     .attr('cx', d => xScale(d))
     .attr('cy', 14.9)
     .attr('r', 2.2)
     .attr('transform', (d, i) => 'translate(0,0)')
     .on('click', (d, i) => {
      })
     }
  }
}, [ st.timeline]);

return (
  <svg viewBox="-70 0 700 700" >
      <g id='evt' ref={evtcont}></g>
      <g id='tln' ref={tlncont}></g>
  </svg>
);
}
